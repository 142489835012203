import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/monkey/Projects/bkc-sajt/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Svoj redovni program, Radio Gračanica, kao općinsko radio-difuzno glasilo, koje je osnovala Opštinska konferencija Socijalističkog narodnog naroda Gračanica, počela je emitirati od 01.03.1969.godine. Krenulo se s nekvalitetnom opremom od svega 50W snage. Vremenom popularnost Radija raste. Program je koncepcijski,sadržajno i tehnički stalno unapredjivan i popravljan.`}</p>
    </PageDescription>
    <Row mdxType="Row">
      <Column mdxType="Column">
Neposredno pred izbijanje rata za Bosnu i Hercegovinu, 1992.-1995. godine, prvi na udaru agresora bili su mediji. Studio i režija Radio Gračanice “dignuti su u vazduh” 25.03.1992., a njen srednjetalasni prijemnik 19.05.1992. godine. Medjutim, Radio Gračanica počinje emitirati svoj ratni program, ovoga puta na ultra kratkim talasima. Danas, Radio Gračanica proizvodi 14 sati sopstvenog programa dnevno.
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ImageCard title="Radio Gračanica" href="/rg" actionIcon="arrowRight" titleColor="light" iconColor="light" className="card-background" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAFHUlEQVQ4y2WTe1DUVRiGf5ldxpxpmqlmpKmJTEMgYBCEhUVhYQFX5RbIglwUEgRWESEQBFFBE1EEQbmksqis1wJUyDEFnEy8lGHmZTJtnDIsRkS5LAsLT2eX9I/6Zt45c27P+b4z3ysN6o3oh4wMGUafyzA89j+Z1k3nho1w7acbHNQdpOV4E/fu3qOnd8C83t9vQHoGM180jUIjI0LGUYZHTHoGFXsj8NvtmzTX7qAkR8PS6FDSkuOJjotH19iC4c51pGewEeMYwwajAI4wOjr6XEajUTwgNDrGgH6YG21N9P3czq2zjezIS8f1Yyvsp71PcYaGwUttSMPD49kYhoYY0gsNDpkhz6FjY2aNCZn2tNs288eFk5zeW8n1o1UsVbkzZaotRXk53L/QgmQ0jl/UDw4y0NfH4NOnIlODGTAKjBqGGNEPYIq/u7uoO1lNx/1W/uz6kd+bK1mflUZe/VnWVB2j8/srSCaY+bLIarC/n/7eXvPfGUWZxqeP6ek4Tdf5FjDoqW1uYFFNDPsfHGFtZwHbzhWyODuX+FIdsXmlfNcqSjbBTCWaYkzo+pWL3GpvovvqGR7f/oGezgs8PL6PgUtnaD9/kcj6GNbfLWT1zVwyOnJQhPgyNy4BpUpF1ZYCkeG/sL4BPSN//UpNTiKRLh9QlBhMbUkBLfV7Ob2ngn1F6ynfWoa6NIq0a+lknMpgmcaPIOdpvDF5MpMmSCRGhIyXfOfWDY7otBz5PJUUlSshqrnERaqZ5+ONo40Nro6OyJydsPpoKu/NeIdZKgdClVasC5VRuMQXG2trPGVOZCWoke4+6CZzXQHh3q7MtrHE2WoqEfN9UDhaY/XuFCynvI3DNEvUgSoW+Ljjq1TiIZeROtsWXWogzdtXkpK+CpWbPUsDPZG6H/VQUlmDwtcfL4WCYN85RAbPJVxAna0/ZLrFW8yweBOPmTYsCl1IUFEur2+R4VvgxaLKIAJPJbG2toow8diyYE9THxoYEr75tuMK+dkZJMdGkLAkivkKOQ6WFjjbWhOxOIYVGzYTv72MSU1xSJejkU6J/zoTidSpYUPbIdJjw4md5440KPrPFIb+PvoedYuWMfKw6wHnWr6ioryMnJp68nRNhBSW8dr2hUg3M5h4WcPEiym8ei0T6XQ4UQ1FZKWk4DXLTnhZrzcD+5/0oh8Yb2BTmJq67HAjfitXM29tERaVCUj38pF+WcOE1sW81KTmxT1KpB1ypu9PYnnacmTChlK/gJj60AQ2CIc8EY3dcEiHJm8dNnMDCFySjGpjOi+0LcOyLZvp2xbwckMA0tH5vPJlNMqdK7A7lkTDNydxs7dF4j+xu6qc6tpyios2EpmYTG5WKjmZGhR1GjaVbWJTahpWtbGEthZz4LCOpvZm4hrzuSxslxAj/nSfto66ujq0Wi179uwlUv0JmVXppAmIOiyMlcmfskqTSHhoMGuyP6Px6wbU4kx7aysnTpygYm81uQX5XO3sJD83D8nJwZbZbi74eHqgmCMnQOVHdFgwIQv8UXrNQR0SQIC/Nyo/JSHz/YkKD2Genw8HDuzn4MF6SrZuIWvVSvOaQjYLKUDhRlVZMc0NRzhar0VbU8G61auoLi9h3xe7qBMqLlxLfW0Nu3eVsrNkM3HqICrFflpSPN6u9ihc7FDKHPCVz0TydBcOEWQvDxkeMmfzOFuMnnJXkbUchYcb3nPczXOlqMJLuMRtliOuM+2FJe2QuziZz8tdncwZ/gN7L0quVBzPGgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Radio Gračanica",
              "title": "Radio Gračanica",
              "src": "/static/d07a780144ddbebd0179040133f4aa84/4552c/radiogracanica.png",
              "srcSet": ["/static/d07a780144ddbebd0179040133f4aa84/7fc1e/radiogracanica.png 288w", "/static/d07a780144ddbebd0179040133f4aa84/a5df1/radiogracanica.png 576w", "/static/d07a780144ddbebd0179040133f4aa84/4552c/radiogracanica.png 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
   </ImageCard>
 </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      